.tweet-content {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.tweet-content > div {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
}